import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {ArticleService} from '../../services/article.service';

@Component({
  selector: 'app-sync',
  templateUrl: './sync.component.html',
  styleUrls: ['./sync.component.css']
})
export class SyncComponent implements OnInit {

  constructor(private userservice: UserService, private articleService: ArticleService) { }

  ngOnInit(): void {
    this.userservice.syncUser();
    // this.articleService.syncArticle();

  }

}
