import {Table} from './table';
import {GerichteInterface} from '../interfaces/gerichte';
import {environment} from '../../environments/environment';
import {UserService} from '../services/user.service';
export class Gerichte extends Table implements GerichteInterface {


  namespace = 'gerichte';
  constructor() {
    super();
    this.name = 'gerichte';
    this.localentpoint = '/meals/'+ UserService.getDepartmentId() + '/';
  }

  async searchCategory(term:any): Promise<void> {
    const category = await this.apiClient.get<any>({
      url: environment.localroot + this.localentpoint + 'search/category/' + term + '/',

    });
    this.data = category;
  }

  abfue = 0;
  alanm = '';
  alcoholh = 0;
  alkoh = '';
  alkol = 0;
  anderefleischsorte = 0;
  antio = 0;
  aqua = 0;
  argmg = '';
  artb4b = '';
  ascvalue = 0;
  aspsae = '';
  aufgetaut = 0;
  ausfischstueckenzusammengefuegt = 0;
  ausfleischstueckenzusammengefuegt = 0;
  ausgelisted = 0;
  azofa = 0;
  balla = 0;
  bestr = 0;
  bio = 0;
  blsschluessel = '';
  blsschluessel1 = '';
  blsschluessel2 = '';
  blsumrechfaktor = '';
  brote = 0;
  butbu = '';
  calci = '';
  caske = 0;
  celmg = '';
  chini = 0;
  chlid = '';
  chole = '';
  clientname = '';
  coffe = 0;
  convenience = 0;
  cysmg = '';
  dcotr = '';
  debeh = '';
  decap = '';
  dinke = 0;
  disac = '';
  docsa = '';
  dolau = '';
  dosah = '';
  dotra = '';
  dsape = '';
  eiall = 0;
  eiara = '';
  eicap = '';
  eicar = '';
  eiklar = 0;
  eisad = '';
  eisat = '';
  eisen = '';
  eisp = 0;
  eiwei = 0;
  erdns = 0;
  erdnssp = 0;
  esami = '';
  eufss = '';
  fairtrade = 0;
  farbs = 0;
  fette = 0;
  fettglasur = 0;
  fisch = 0;
  fischsonst = 0;
  fischsp = 0;
  fluor = '';
  flutr = '';
  folat = '';
  folsr = '';
  fssss = 0;
  fzfle = 0;
  galas = '';
  geffl = 0;
  gefluegel = 0;
  gemuese = 0;
  gente = 0;
  genve = 0;
  gerst = 0;
  gerzt = 0;
  gesch = 0;
  gesft = 0;
  gewac = 0;
  glcin = '';
  gluli = '';
  glutamat = 0;
  glute = 0;
  glutesp = 0;
  glutr = '';
  gluts = '';
  glyts = '';
  hafer = 0;
  halal = 0;
  harns = '';
  hasns = 0;
  hecap = '';
  hecas = '';
  hefee = 0;
  hepta = '';
  herkunftfleisch = '';
  hexas = '';
  hexpa = '';
  hisin = '';
  huehnchen = 0;
  isolm = '';
  joddd = '';
  jodid = '';
  jodsz = 0;
  kalb = 0;
  kaliu = '';
  kamut = 0;
  karotte = 0;
  kcale = 0;
  khdei = 0;
  kjene = 0;
  knoblauch = 0;
  kochs = 0;
  koffeingetraenke = 0;
  koffeinlm = 0;
  kohle = 0;
  konse = 0;
  koriander = 0;
  koscher = 0;
  kostenstelle = '';
  krebs = 0;
  krebssp = 0;
  kufet = '';
  kupfe = '';
  lacmi = '';
  laktosefrei = 0;
  lamm = 0;
  lanfe = '';
  leucm = '';
  ligmg = '';
  linle = '';
  linol = '';
  lupin = 0;
  lupinsp = 0;
  lysmg = '';
  macns = 0;
  magne = '';
  mais = 0;
  malma = '';
  mande = 0;
  manga = '';
  manni = '';
  manufacturername = '';
  methm = '';
  mifet = '';
  milchpulver = 0;
  milei = 0;
  millk = 0;
  millksp = 0;
  miner = '';
  minera = '';
  mitpo = '';
  molkeneiweiss = 0;
  mon1m = '';
  monod = '';
  msc = 0;
  mufss = '';
  nachhaltig = 0;
  nameb4b = '';
  natam = 0;
  native = 0;
  natri = 0;
  niaci = '';
  niami = '';
  nitps = 0;
  nitrt = 0;
  nonde = '';
  occap = '';
  ocste = '';
  olinr = '';
  olire = '';
  om3fe = '';
  om6fe = '';
  orsaeu = '';
  ostea = '';
  parns = 0;
  pecns = 0;
  pende = '';
  pflanzeneiweiss = 0;
  pflanzensterine = 0;
  phenl = 0;
  pheny = '';
  phosh = 0;
  phosp = '';
  phyti = '';
  pista = 0;
  pohem = '';
  polys = '';
  popem = '';
  pourm = '';
  proin = '';
  psver = '';
  purmg = '';
  refb4 = '';
  retin = '';
  rindfleisch = 0;
  rogge = 0;
  sacrue = '';
  sahne = 0;
  saliz = '';
  sauerstoff = 0;
  schal = 0;
  schalsp = 0;
  schuz = 0;
  schwe = 0;
  schwef = '';
  sdiox = 0;
  sdioxsp = 0;
  seawa = 0;
  selen = '';
  selle = 0;
  sellesp = 0;
  senff = 0;
  senffsp = 0;
  sermg = '';
  sesam = 0;
  sesamsp = 0;
  sobit = '';
  sojaa = 0;
  sojaasp = 0;
  staemg = '';
  staer = 0;
  suess = 0;
  suessholz = 0;
  suessholzbluthochdruck = 0;
  supplierartnr = 0;
  suppliername = '';
  suzuc = '';
  szcaro = '';
  tafsu = 0;
  tauri = 0;
  telig = '';
  temyr = '';
  thrin = '';
  tryph = '';
  tyrmg = '';
  valmg = '';
  vegan = 0;
  veget = 0;
  verwendungvonmilch = 0;
  vib12 = '';
  vib3n = '';
  vib5p = '';
  vib7b = '';
  vieto = '';
  vigra = 0;
  vigrasub = 0;
  vigrv = 0;
  vikph = '';
  vitaa = '';
  vitac = '';
  vitad = '';
  vitae = 0;
  vitb1 = '';
  vitb2 = '';
  vitb6 = '';
  vollkorn = 0;
  walns = 0;
  wasba = '';
  wasse = '';
  wasub = '';
  weich = 0;
  weichsp = 0;
  weize = 0;
  wgschluessel = 0;
  wild = 0;
  wursthuelle = 0;
  xy100 = '';
  zinkk = '';
  zucke = 0;
  zuckr = 0;
  zugesetzteeiweisse = 0;
  zugesetzteswasser = 0;
  zutaten = '';
  zutateneoda = 0;
  zutatenersatz = 0;


}
