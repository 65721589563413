
export class Nutrient {

  get(): any {
    return  [
      {name: 'Energie Kcal', ident: 'kcale'},
      {name: 'Energie KJ ', ident: 'kjene'},
      {name: 'Eiweiß ', ident: 'eiwei'},
      {name: 'Fett ', ident: 'fette'},
      {name: 'gesättigte Fettsäuren ', ident: 'fssss'},
      {name: 'Kohlenhydrate ', ident: 'kohle'},

      {name: 'Zucker ', ident: 'zucke'},
      // {name: 'Ballaststoffe ', ident: 'balla'},
      // {name: 'Broteinheit ', ident: 'brote'},
      {name: 'Salz ', ident: 'kochs'},


    ]
  }

}

