import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Gerichte} from '../../entity/gerichte';
import {Infos} from '../../Model/infos';
import {Extras} from '../../Model/extras';
import {Allergenes} from '../../Model/allergenes';
import {Core} from '../../core';
import {MenuComponents} from '../../Helper/MenuComponents';
import {Menu} from '../../entity/menu';
import {UserService} from '../../services/user.service';
import {NutrientXML} from '../../Model/nutrientxml';

@Component({
  selector: 'app-produkt-detail-sp',
  templateUrl: './produkt-detail-sp.component.html',
  styleUrls: ['./produkt-detail-sp.component.css']
})
export class ProduktDetailSPComponent extends Core implements OnInit {

  term = '';
  Tabs: any;
  id = '';
  title: any;
  day: any;
  days: any = [];
  private marker = 0;
  kw: any;
  private tabswitcher: any;
  private item: any;
  compID: string;



  constructor(private actRoute: ActivatedRoute) {
    super();
    this.term = this.actRoute.snapshot.params.name;
    this.id = this.term.split('__')[1];

    const menuHelper = new Menu();
    this.day  = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
    this.kw = window.location.pathname.split('/')[window.location.pathname.split('/').length - 3];
    const weekday = [{day: 'montag', value: 0} , {day: 'dienstag', value: 1} , {day: 'mittwoch', value: 2} , {day: 'donnerstag', value: 3},
      {day: 'freitag', value: 4} , {day: 'samstag', value: 5} , {day: 'sonntag', value: 6}];
    for (let x = 0; x < weekday.length; x++){
      if (weekday[x].day === this.day) {
        this.marker = weekday[x].value;
      }
    }
    this.title = decodeURIComponent(window.location.pathname.split('/')[window.location.pathname.split('/').length - 1].split('_')[1]);
    this.compID = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1].split('_')[0];

    menuHelper.getWeek(this.kw).then(() => {
      for (const lieferung of menuHelper.data[0].Auslieferung) {
        const node = lieferung.Speiseplaene[0].Speiseplan[0].Menuelinien[0].Menuelinie;
        for (const item of node) {
          if (item.Bezeichnung[0] === 'Salatbuffet' || (UserService.getUser() == "STG" && item['Bezeichnung'][0] === 'Vorspeise 1' )) {
            // Zum Holen der Compnenten von einer Menulinie
            this.days.push(item);
          }
        }
      }
      this.item = MenuComponents.getComponentByName(this.days[this.marker],this.compID);

      // Alle Saltebuffet sind nun in diesen Array 0 = Montag und so weiter

      const regex = /(,)/gmi;
      const meal = this.item;
      this.Tabs = [
        { label: 'Nährwerte', content: this.nutrients(meal.Naehrwerte[0].Naehrwert) },
        { label: 'Allergene', content: this.allergenes(meal.Allergene[0].Allergen) },
        { label: 'Zusatzstoffe', content: this.extras(meal.Zusatzstoffe[0].Zusatzstoff)},
        { label: 'Informationen', content: this.infos(meal.Information[0].Information) },
       /* { label: 'Zutaten', content: '' },*/
      ];


    });

    this.tabsetter;

  }



  ngOnInit(): void {

    document.body.className = 'details';
  }

  tabsetter(Tabid: any, Tabtarget: any): any {


    let activePin;
    let deactivePin;
    let activeTab;
    let deactiveTab;

    let x = 0;
    const tabs = ['tab1', 'tab2', 'tab3', 'tab4', 'tab5'];
    const pins = ['Pin1', 'Pin2', 'Pin3', 'Pin4', 'Pin5'];

    for (x = 0; x  < tabs.length; x++){
      if (Tabid === pins[x]) {
        activePin = document.getElementById(Tabid) as HTMLDivElement;
        activeTab = document.getElementById(Tabtarget) as HTMLDivElement;

        activePin.classList.add('mat-tab-label-active');
        activeTab.style.display = 'block';

      } else {
        deactivePin = document.getElementById(pins[x])  as HTMLDivElement;
        deactivePin.classList.remove('mat-tab-label-active');
        deactiveTab = document.getElementById(tabs[x])  as HTMLDivElement;
        deactiveTab.style.display = 'none';
      }
    }
    return;

  }

  infos(response: any): any {
    let holder = '';
    if(response !== undefined) {
      for (const item of response) {
        if (item['Wert'][0] === '1' || item['Wert'][0] === 'true') {
          holder += '<p>' + item['Bezeichnung'][0] + '</p>';

        }
      }
      if (holder === '') {
        holder = 'Keine Angabe';
      }
    } else {
      holder = 'Keine Angabe';
    }
    return holder;
  }

  extras(response: any): any {
    let holder = '';
    let counter = 0;
    if(response !== undefined) {
    for (const item of response) {
      if (item['Wert'][0] === '1' || item['Wert'][0] === 'true') {
        counter++;
        holder += '<p>' + item['Bezeichnung'][0] + '</p>';
      }
    }
    if (counter === 0) {
      holder = 'keine Zusatzstoffe';
    }
    } else {
      holder = 'Keine Angabe';
    }
    return holder;
  }

  nutrients(response: any): any {

    let holder = '<div class="row mb-4"><div class="col-12">Durchschnittliche Nährwerte pro 100 g</div></div>';
    const nutris: any = new NutrientXML();
    if(response !== undefined) {
      for(const nutri in response) {
        console.log(nutri);
        console.log(nutris.get());
        const items = nutris.get();
          for (const label in items) {
            if (response[nutri]['Bezeichnung'][0] === items[label].ident) {
              if ( items[label].ident === 'GesaettigteFettsaeuren' || items[label].ident === 'Zucker' ) {
                holder += '<div class="row mb-4 mt_n1"><div class="col-6 ps-5">davon ' + items[label].name + '</div><div class="col-3 text-right">  '
                  + this.fixDimension(items[label].ident,response[nutri]['Wert'][0], response[nutri]['Einheit'][0])  + ' ' + items[label].messure + '</div></div>'; // parseFloat().toFixed(2).replace('.','
              } else if(items[label].ident === "Energie") {
                holder += '<div class="row mb-4"><div class="col-6" >' + items[label].name + '</div><div class="col-3 text-right" > '
                  + this.fixDimension(items[label].ident, response[nutri]['Wert'][0], response[nutri]['Einheit'][0]  ) + ' ' + items[label].messure + '</div></div>';
              } else {
                holder += '<div class="row mb-4"><div class="col-6" >' + items[label].name + '</div><div class="col-3 text-right" > '
                  + this.fixDimension(items[label].ident, response[nutri]['Wert'][0], response[nutri]['Einheit'][0]) + ' ' + items[label].messure + '</div></div>';
              }
            }
          }
      }
    } else {
      holder = 'Keine Angabe';
    }
    return holder;
  }

  fixDimension(index: string , value: any, einheit: string): any {
    if (index === 'Energie') {
      if (index === 'Energie') {
        const kcal = value / 10;
        return kcal.toFixed(0).replace('.', ',');
      }
    }
    else if(index === 'EnergieKj') {
      const kcal = value / 10;
      return kcal.toFixed(0).replace('.', ',');
    }
    if (index === 'Kochsalz' ) {
      const cur =  parseFloat(value) / 1000;
      return cur.toFixed(2).replace('.', ',');
    }
    if ( einheit === "mg") {
       const kcal = value / 1000;
      return kcal.toFixed(1).replace('.', ',');
    }
  }


  allergenes(response: any): any {
    let holder = '';
    let counter = 0;
    if(response !== undefined) {
    for (const item of response) {
      if (item['Wert'][0] === '1' || item['Wert'][0] === 'true') {
        holder += '<p>' +  item['Bezeichnung'][0] + '</p>';
      }
      counter++;
    }
    if (counter === 0) {
      holder = 'keine Allergene';
    }
    } else {
      holder = 'Keine Angabe';
    }
    return holder;
  }

}
