import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import {SwitcherComponent} from './components/switcher/switcher.component';
import {HasherHelper} from './Helper/hasher';
import { LoginComponent } from './components/login/login.component';
import {FormBuilder, ReactiveFormsModule} from '@angular/forms';
import {LoginActivate} from './guards/LoginActivate';
import {GoodsGroupComponent} from './components/goods_group/goods_group.component';
import { SyncComponent } from './components/sync/sync.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { BackComponent } from './components/back/back.component';
import {ProductDetailComponent} from './components/produktDetail/productDetail.component';
import {MatTabsModule} from '@angular/material/tabs';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SearchWordComponent } from './components/search-word/search-word.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ComponentsComponent } from './components/components/components.component';
import { ComponentsKwComponent } from './components/components-kw/components-kw.component';
import { ComponentsappetizersComponent } from './components/componentsappetizers/componentsappetizers.component';
import { ProduktDetailSPComponent } from './components/produkt-detail-sp/produkt-detail-sp.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SwitcherComponent,
    LoginComponent,
    GoodsGroupComponent,
    SyncComponent,
    SearchResultComponent,
    BackComponent,
    ProductDetailComponent,
    SearchWordComponent,
    SettingsComponent,
    ComponentsComponent,
    ComponentsKwComponent,
    ComponentsappetizersComponent,
    ProduktDetailSPComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: true}),
    MatTabsModule,
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent],
  providers: [
    HasherHelper,
    FormBuilder,
    LoginActivate,
    {provide: LOCALE_ID, useValue: 'de-DE'}
  ]
})
export class AppModule { }
