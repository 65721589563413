
export class Infos {

  get(): any {
    return  [
      {name: 'enthält Glutamat', ident: 'glutamat'}


    ]
  }

}
