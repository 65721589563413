import { Injectable } from '@angular/core';
import {ApiClientService} from './Http/api-client.service';
import {UserInterface} from '../interfaces/user';
import { environment } from '../../environments/environment';
import {UserHashInterface} from '../interfaces/userHash';
import {HasherHelper} from '../Helper/hasher';
import {User} from '../entity/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  public entpoint = 'dw/Request/kladde/GetUsers';
  public apiClient: ApiClientService;
  public users: UserInterface[];

  constructor(apiClientService: ApiClientService, private hasher: HasherHelper) {
      this.apiClient = apiClientService;
      this.users = [];

  }

  public static getUser(): string {
    const username = localStorage.getItem('user');
    if (username !== null) {
      return JSON.parse(username).value;
    }

    return '';
  }

  public static getDepartmentId(): string {
    const hasher = new HasherHelper();
    hasher.setKey(environment.hash);
    return hasher.decryptDepartmentId(localStorage.getItem('departmentid'));
  }

  // I load the list of friends.
  public async syncUser(): Promise<UserInterface[] | undefined> {

    try {
      const userHash =  await this.apiClient.get<UserHashInterface>({
        url: environment.root + this.entpoint,

      });
      this.hasher.setKey(environment.hs);
      for (const user of this.hasher.decrypt(userHash.data.users)) {
          this.users.push(user);
      }
      // console.log(this.users);
      for (const user of this.users) {
          this.hasher.setKey(environment.hash);
          user.lastname = this.hasher.encryp(user.lastname);
          user.firstname = this.hasher.encryp(user.firstname);
          user.departmentid = this.hasher.encryp(user.departmentid);
          this.apiClient.post<any>({
            url: environment.localroot + '/users/'

          }, user);
      }
;

      return this.users;

    } catch ( error ) {

      console.error( error );
      return undefined;

    }

  }
}
