import { Injectable } from '@angular/core';
import {IndexedDBAngular} from 'indexeddb-angular';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {
  public static instance: DatabaseService | null = null;
  private db: IndexedDBAngular | undefined;

  public static getInntance(): any {
      if (DatabaseService.instance == null) {
        DatabaseService.instance = new DatabaseService();

        DatabaseService.instance.db = new IndexedDBAngular('menuplan', 1);
      }
      return DatabaseService.instance;
  }

  constructor() {}
}
