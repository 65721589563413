import { Component, OnInit } from '@angular/core';
import {Core} from '../../core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-search-word',
  templateUrl: './search-word.component.html',
  styleUrls: ['./search-word.component.css']
})
export class SearchWordComponent extends Core implements OnInit  {

  constructor(private router: Router) {
    super();
  }

  ngOnInit(): void {
    document.body.className = 'goodsGroup';
  }

  sendSearch(event: any) : void {
    event.preventDefault();
    let url = '/result/search/';
    const term = document.getElementById('term') as HTMLInputElement;
    url += term?.value;
    this.router.navigate([url ]);

  }

}
