
export class Extras {

  get(): any {
    return  [
      {name: 'Azofarbstoffe', ident: 'azofa'},
      {name: 'Konservierungsstoffe', ident: 'konse'},
      {name: 'Antioxidationsmittel', ident: 'antio'},
      {name: 'Geschmacksverstärker', ident: 'gesch'},
      {name: 'Süßssungsmittel', ident: 'suess'},
      {name: 'Phenylalanin', ident: 'phenl'},
      {name: 'Phosphat', ident: 'phosh'},
      {name: 'gewachst', ident: 'gewac'},
      {name: 'geschwärzt', ident: 'gerzt'},
      {name: 'geschwefelt', ident: 'gesft'},
      {name: 'Schwein tierisches Fett', ident: 'schwe'},
      {name: 'Feinzerkleinertes Fleisch', ident: 'fzfle'},
      {name: 'Zucker', ident: 'zuckr'},
      {name: 'abführend', ident: 'abfue'},
      {name: 'Schutzatmosphäre', ident: 'schuz'},
      {name: 'Milcheiweß (in Fleischerzeugnissen)', ident: 'milei'},
      {name: 'Koffein', ident: 'coffe'},
      {name: 'Geflügelfleisch', ident: 'geffl'},
      {name: 'Chinin', ident: 'chini'},
      {name: 'Tafelsüsse', ident: 'tafsu'},
      {name: 'Alkoholhaltig', ident: 'alkol'},
      {name: 'bestrahlt', ident: 'bestr'},
      {name: 'aus gentechnischen LM', ident: 'gente'},
      {name: 'gentechnisch verändert', ident: 'genve'},
      {name: 'Staer', ident: 'staer'},
      {name: 'Jodsalz', ident: 'jodsz'},
      {name: 'Taurin', ident: 'tauri'},
      {name: 'Nitrat', ident: 'nitrt'},
      {name: 'Nitritpökelsalz', ident: 'nitps'}

    ];
  }

}
