import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {


  isLoggedIn(): boolean {
      if (localStorage.getItem('isLoggedIn') !== undefined )   {
          const isLoggedIn = localStorage.getItem('isLoggedIn');
          if (isLoggedIn !== null) {
          const state = JSON.parse(isLoggedIn);
          if(state.expiry >= new Date().getTime() && state.value === 'true') {
            const date = new Date();
            date.setMinutes(date.getMinutes() + 30);
            const item = {
              value: 'true',
              expiry: date.getTime(),
            };
            localStorage.setItem('isLoggedIn', JSON.stringify(item));
            return true;
          } else {
            localStorage.removeItem('isLoggedIn');
            return false;
          }
        }  else {
          localStorage.removeItem('isLoggedIn');
          return false;
        }

      } else {
        return false;
      }
  }
}
