import {Table} from './table';
import {CategoryInterface} from '../interfaces/category';
import {environment} from '../../environments/environment';
export class Categroy extends Table implements CategoryInterface {

  id = 0;
  WarengruppeKunde = '';
  WarengruppenschlsselB4B = '';
  Kunde = '';
  wgkey = '';


  namespace = 'Catgeory';
  constructor() {
    super();
    this.name = 'category';
    this.data = [{ id: 0, username: '' }];
    this.localentpoint = '/category/' + environment.departmentid + '/';
  }

  async getCategory(): Promise<void> {
    const category = await this.apiClient.get<any>({
      url: environment.localroot + '/category/' + this.wgkey + '/'

    });
    this.data = category;
  }

  async getAllActiveCategory(): Promise<void> {
    const category = await this.apiClient.get<any>({
      url: environment.localroot + this.localentpoint + 'filter/'

    });
    this.data = category;
  }
}
