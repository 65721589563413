import axios from 'axios';
import { AxiosInstance } from 'axios';
import { ErrorHandler } from '@angular/core';
import { Injectable } from '@angular/core';
import {GetOptions} from '../../interfaces/GetOptions';
import {ErrorResponse} from '../../interfaces/ErrorResponse';


@Injectable({
  providedIn: 'root'
})
export class ApiClientService {

  private axiosClient: AxiosInstance;
  private errorHandler: ErrorHandler;

  // I initialize the ApiClient.
  constructor( errorHandler: ErrorHandler ) {

    this.errorHandler = errorHandler;

    // The ApiClient wraps calls to the underlying Axios client.
    this.axiosClient = axios.create({
      timeout: 9000,
      headers: { }
    });

  }


  // I perform a GET request with the given options.
  public async get<T>( options: GetOptions ): Promise<T> {

    try {

      const axiosResponse = await this.axiosClient.request<T>({
        method: 'get',
        url: options.url,
        params: options.params
      });

      return( axiosResponse.data );

    } catch ( error ) {

      return( Promise.reject( this.normalizeError( error ) ) );

    }

  }

  // I perform a GET request with the given options.
  public async post<T>( options: GetOptions, body: any ): Promise<T> {

    try {

      const axiosResponse = await this.axiosClient.request<T>({
        method: 'post',
        url: options.url,
        data: body,
        params: options.params
      });

      return( axiosResponse.data );

    } catch ( error ) {

      return( Promise.reject( this.normalizeError( error ) ) );

    }

  }

  // Errors can occur for a variety of reasons. I normalize the error response so that
  // the calling context can assume a standard error structure.
  private normalizeError( error: any ): ErrorResponse {

    this.errorHandler.handleError( error );

    // NOTE: Since I'm not really dealing with a production API, this doesn't really
    // normalize anything (ie, this is not the focus of this demo).
    return({
      id: '-1',
      code: 'UnknownError',
      message: 'An unexpected error occurred.'
    });

  }

}
