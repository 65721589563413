<div id="wrapper">

  <div class="parent">
    <div class="row ">
      <div class="col-3"></div>
      <div class=" col-9">

        <div class="div1"><h1>Suche nach Komponenten</h1></div>
        <div class="div2">
          <div class="container">
            <div clasS="row">
              <div class="col col-4 p0">
                <select id="kw" class="tupper" #selectkw (change)="rewrite(selectkw.value,false)">
                  <option></option>
                  <option *ngFor="let c of kw"  value="{{c}}" selected="{{(c === title ? 'selected':'')}}">KW {{c}} </option>
                </select>
              </div>
              <div class="col col-8 p0">
                <select id="day" #selectday (change)="rewrite(selectday.value,true)">
                  <option *ngFor="let days of weekDays"  value="{{days.value}}"  selected="{{days.selected}}">{{days.day}}</option>
                </select>
              </div>
            </div>
           <!-- <div class="row" *ngFor="let component of activeItem.data;">
              <div class="col col-4 type">{{component.type}}</div>
              <div class="col col-8 name">{{component.name}}</div>
            </div>-->
            <div class="row">
              <div class="col col-4 type">Vorspeisen</div>
              <div class="col col-8 name"><a [routerLink]="['/components/appetizers/Salatbuffet/' + title + '/' + day ]">Salatbuffet</a></div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <app-back></app-back>

</div>
