import { Component, OnInit } from '@angular/core';

import {Core} from '../../core';
import {Categroy} from '../../entity/category';
import {Router} from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './goods_group.component.html',
  styleUrls: ['./goods_group.component.css'],
})
export class GoodsGroupComponent extends Core implements OnInit  {
  public category: Categroy;
  constructor(private router: Router) {
    super();
    this.category = new Categroy();
    this.category.getAllActiveCategory().then(() => {
      for (const data of this.category.data) {
        data.wgkey = data.WarengruppenschlüsselB4B;
      }
    });
  }

  search(link: any, name: any ): void {
    // const selectedLaw: any = evt.target.value;
    this.router.navigate(['/result/warengruppe/' + link + '/' + name.replace('/', '-').replace(' ', '*') + '/'  ]);

  }

  ngOnInit(): void {
    document.body.className = 'goodsGroup';
  }

}
