<div id="wrapper">

  <div class="parent">
    <div class="row ">
     <div class="col-3"></div>
      <div class=" col-9">

        <div class="div1"><h1>Warengruppe auswählen</h1></div>
        <div class="div2">
        <div class="container grayout">
        <div class="row">
          <div class="col-6" *ngFor="let cat of category.data;">
            <a (click)="search(cat.wgkey, cat.WarengruppeKunde)" >{{cat.WarengruppeKunde}}</a>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  </div>



  <app-back></app-back>

</div>
