import { Component, OnInit } from '@angular/core';

import {Core} from '../../core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent extends Core implements OnInit  {

  constructor() {
    super();
  }


  ngOnInit(): void {
    document.body.className = 'home';
  }

}
