
export class Allergenes {

  get(): any {
    return  [
      {name: 'Gluten', ident: 'glute', tab: false},
      {name: 'Weizen', ident: 'weize', tab: true},
      {name: 'Roggn', ident: 'rogge', tab: true},
      {name: 'Gerste', ident: 'gerst', tab: true},
      {name: 'Hafer', ident: 'hafer', tab: true},
      {name: 'Dinkel', ident: 'dinke', tab: true},
      {name: 'Kamut', ident: 'kamut', tab: true},
      {name: 'Schalenfrüchten', ident: 'schal', tab: false},
      {name: 'Walnuss', ident: 'walns', tab: true},
      {name: 'Haselnuss', ident: 'hasns', tab: true},
      {name: 'Mandel', ident: 'mande', tab: true},
      {name: 'Paranuss', ident: 'parns', tab: true},
      {name: 'Pekanuss', ident: 'pecns', tab: true},
      {name: 'Macadamianuss', ident: 'macns', tab: true},
      {name: 'Cashewkern', ident: 'caske', tab: true},
      {name: 'Pistazien', ident: 'pista', tab: true},
      {name: 'Krebstiere', ident: 'krebs', tab: false},
      {name: 'Ei', ident: 'eiall', tab: false},
      {name: 'Fisch', ident: 'fisch', tab: false},
      {name: 'Erdüsse', ident: 'erdns', tab: false},
      {name: 'Soja', ident: 'sojaa', tab: false},
      {name: 'Milch', ident: 'millk', tab: false},
      {name: 'Sellerie', ident: 'selle', tab: false},
      {name: 'Senf', ident: 'senff', tab: false},
      {name: 'Sesam', ident: 'sesam', tab: false},
      {name: 'Schwefeldioxid Sulfite', ident: 'sdiox', tab: false},
      {name: 'Lupine', ident: 'lupin', tab: false},
      {name: 'Weichtiere', ident: 'weich', tab: false}

    ]
  }

}
