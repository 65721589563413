<div id="wrapper">

  <div class="parent">
    <div class="row">
      <div class="col-5"></div>
      <div class="col-7 padding-content">
        <div class="holder"><p class="m-2"><a [routerLink]="['/step3a']">Suche nach Warengruppen</a></p></div>
        <div class="holder"><p class="m-2"><a [routerLink]="['/search-word']">Suche nach Wortbestandteilen</a></p></div>
        <div class="holder"><p class="m-2"><a [routerLink]="['/components']">Suche nach Komponenten</a></p></div>
      </div>
    </div>

  </div>




  <app-back></app-back>

</div>
