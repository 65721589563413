import {Component,OnInit} from '@angular/core';
import {Core} from '../../core';
import {Router} from '@angular/router';
import {ApiClientService} from '../../services/Http/api-client.service';
import {environment} from '../../../environments/environment.dev';
import {Menu} from '../../entity/menu';
import {MenuComponents} from '../../Helper/MenuComponents';

@Component({
  selector: 'app-components-kw',
  templateUrl: './components-kw.component.html',
  styleUrls: ['./components-kw.component.css']
})
export class ComponentsKwComponent extends Core implements OnInit {

  title: any;
  day: any;
  item: any;
  days: any = [];
  activeItem: any;
  data: any;
  weekDays: any;
  kw: any;

  constructor(private router: Router, private client: ApiClientService) {
    super();

    this.item = {
      data:
        [{
          kw: 'kw25',
          day: 'montag',
          data: [{
            type: 'Suppe Menu 1 ',
            name: 'Erbsensuppe'
          }
            ,
            {
              type: 'Suppe Menu 2 ',
              name: 'Hochzeitssuppe'
            }
            ,
            {
              type: 'Menu 1',
              name: 'Zanderfilet mit Bratkartoffeln'
            },
            {
              type: 'Menu 2',
              name: 'Sauerbraten mit Salzkartoffeln und Sauce'
            },
            {
              type: 'Dessert 1',
              name: 'Vanillepudding'
            },
            {
              type: 'Dessert 2',
              name: 'Grießbrei mit Heidelbeersauce'
            }]
        }, {
          kw: 'kw26',
          day: 'dienstag',
          data: [{
            type: 'Suppe Menu 1 ',
            name: 'Erbsensuppe'
          }
            ,
            {
              type: 'Suppe Menu 2 ',
              name: 'Hochzeitssuppe'
            }
            ,
            {
              type: 'Menu 1',
              name: 'Zanderfilet mit Bratkartoffeln'
            },
            {
              type: 'Menu 2',
              name: 'Sauerbraten mit Salzkartoffeln und Sauce'
            },
            {
              type: 'Dessert 1',
              name: 'Vanillepudding'
            },
            {
              type: 'Dessert 2',
              name: 'Grießbrei mit Heidelbeersauce'
            }]
        }]
    };
    const menuHelper = new Menu();
    menuHelper.getAvailWeek().then(() => {

      this.kw = menuHelper.data;
    });
    this.title = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
    this.day = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];

    this.weekDays = [{day: '', value: '', selected: ''} ,
      {day: 'Montag', value: 'montag', selected: (this.day === 'montag' ? 'selected' : '')} ,
      {day: 'Dienstag', value: 'dienstag', selected: (this.day === 'dienstag' ? 'selected' : '')} ,
      {day: 'Mittwoch', value: 'mittwoch', selected: (this.day === 'mittwoch' ? 'selected' : '')} ,
      {day: 'Donnerstag', value: 'donnerstag', selected: (this.day === 'donnerstag' ? 'selected' : '')},
      {day: 'Freitag', value: 'freitag', selected: (this.day === 'freitag' ? 'selected' : '')},
      {day: 'Samstag', value: 'samstag', selected: (this.day === 'samstag' ? 'selected' : '')},
      {day: 'Sonntag', value: 'sonntag', selected: (this.day === 'sonntag' ? 'selected' : '')}];



    menuHelper.getWeek(this.title).then(() => {
      for (const lieferung of menuHelper.data[0]['Auslieferung']) {
        const node = lieferung['Speiseplaene'][0]['Speiseplan'][0]['Menuelinien'][0]['Menuelinie'];
        for (const item of node) {
          // console.log(item);
          if (item['Bezeichnung'][0] === 'Salatbuffet') {
            // Zum Holen der Compnenten von einer Menulinie
            // console.log ( MenuComponents.getComponent(item) );
            this.days.push(item);
          }
        }
      }
      // Alle Saltebuffet sind nun in diesen Array 0 = Montag und so weiter
      // console.log(this.days);
    });

    for (let x = 0; x < this.item.data.length; x++) {
      if (this.item.data[x].kw === this.title && this.item.data[x].day === this.day) {
        this.activeItem = this.item.data[x];
      }
    }
  }

  rewrite(value: any, mode: any): void{
    const kw = document.getElementById('kw') as HTMLSelectElement;
    const day = document.getElementById('day') as HTMLSelectElement;
    window.location.href = '/components/group/' + kw.value + '/' + day.value;
  }

  ngOnInit(): void {
    document.body.className = 'componentsKW';
  }



}
