
    <div id="wrapper">
    <div class="second">


      <div class="container holder pt-3">
        <h2 class="title mt-0">{{ title }}</h2>
        <div class="row">
          <div class="col-6 fitcontent" *ngFor="let meal of results;">
                <a routerLink="/productDetail/{{ meal.name }}__{{ meal.id }}" routerLinkActive="active" ariaCurrentWhenActive="page">{{ meal.name }}</a>
          </div>
        </div>
      </div>
    </div>

  <app-back></app-back>
    </div>

