import {DatabaseService} from '../services/database';
import {IndexedDBAngular} from 'indexeddb-angular';
import {User} from './user';
import {AxiosInstance} from 'axios';
import {ApiClientService} from '../services/Http/api-client.service';
import {ErrorHandler} from '@angular/core';
import {environment} from '../../environments/environment';


export class Table {
  protected db: IndexedDBAngular;
  public name: any;
  public id: any;
  public data: any;
  public localentpoint = '';
  protected namespace: any;
  protected apiClient: ApiClientService;
  protected entity: any;
  constructor( ) {
    this.db = DatabaseService.getInntance().db;
    const errorHandler = new ErrorHandler();
    this.apiClient = new ApiClientService(errorHandler);
  }


  public async findById(key: any): Promise<void> {
    const user = await this.apiClient.get<any>({
      url: environment.localroot + this.localentpoint + key

    });
    this.data = user;
  }
  public findBy(key: string, value: any): void {
    /*this.db.createStore(14, this.createCollections)
      .then( (result) => {
        this.db.getByIndex(this.name, key, value).then((entity) => {
          console.log(entity);
        }, (error) => {
          console.log(error);
        });
        },
        (error) => {
          console.log(error);
        }
      );*/
    }

  public async search(term: string ): Promise<any> {
    const entity = await this.apiClient.get<any>({
      url: environment.localroot + this.localentpoint + 'search/' + term + '/'

    });

    this.data = entity;
  }

  public async findAll(): Promise<any> {
    const entity = await this.apiClient.get<any>({
      url: environment.localroot + this.localentpoint

    });

    this.data = entity;
  }

  public save(): void {
    const array = Object.keys(this);
    const data: any  = {};
    for (let x = 0; x < array.length; x++) {
      if (array[x] === 'db' || array[x] === 'namespace' || array[x] === 'createCollections'
        || array[x] === 'name' || array[x] === 'data' || array[x] ===  'localentpoint' || array[x] === 'apiClient') {
        delete array[x];

      } else {
        type ObjectKey = keyof typeof this;
        const key = array[x] as  ObjectKey;
        data[key] = this[key];
      }
    }
    this.apiClient.post<any>({
      url: environment.localroot + this.localentpoint
    }, data);
  }




}
