<div id="wrapper">

  <div class="parent">
    <div class="row ">
      <div class="col-3"></div>
      <div class=" col-9">

        <div class="div1"><h1>Suche nach Komponenten</h1></div>
        <div class="div2">
          <div class="container">
            <div class="row">
              <div class="col col-8 nk">nach Kalenderwoche</div>
              <div class="col col-4 p21">
                <select #selectweek (change)="rewrite(selectweek.value,false)">
                  <option></option>
                  <option *ngFor="let c of kw"  value="{{c}}">KW {{c}}</option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col col-8 afterk">nach Komponentengruppe</div>
              <div class="col col-4 p21">
                <select #selectgroup (change)="rewrite(selectgroup.value,true)">
                  <option></option>
                  <option value="Salatbuffet">Salatbuffet</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <app-back></app-back>

</div>
