
/**
 * @typedef OwnerNameProps
 * @prop {string} messageValue ID des Language Key
 * @author Marcel Ugurcu
 * @version 0.0.1
 */
interface OwnerNameProps {
  messageValue?: any;
}

/**
 *
 * @param {OwnerNameProps} props Übergabe Werte für OwnerName
 * @constructor
 * @returns  backValue part of now Locale messageValue back and included ENV owner on String.
 * @author Marcel Ugurcu
 * @version 0.0.1
 * Example
 * @example
 * let preperatorOwnerName = new OwnerName(messageValue)
 * messageValue = preperatorOwnerName.Init();
 *
 */

class OwnerName implements  OwnerNameProps {
  /**
   * Contructor funktion die alles aus den Interface gloabl abrufbar macht
   * @param messageValue
   * @constructor
   */
  constructor(public messageValue: any) {
  }

  /**
   * Initatilisiert OwnerName
   * @public
   */

  public Init(): any {
    const replaceString = this.messageValue;

    let owner;
    owner = 'Best4Bps';

    return replaceString + ' ' + owner;

  }
}

export default OwnerName;
