import deMessages from './de_DE.json';
import enMessages from './en_EN.json';
const deLang = {
    messages: {
        ...deMessages
    },
    locale: 'de',
};
const EnLang = {
    messages: {
        ...enMessages
    },
    locale: 'us',
};

const AppLocale: any = {
    de: deLang,
    us: EnLang
};

export default AppLocale;
