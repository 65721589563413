import { Component, OnInit } from '@angular/core';
import {Core} from '../../core';
import {Gerichte} from '../../entity/gerichte';
import {Categroy} from '../../entity/category';

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent extends Core implements OnInit {

  private type: string;
  private term: any;
  public title: any;
  public results: any;

  constructor() {
    super();
    const data = window.location.pathname.split('/');

    this.type = data[2];
    this.term = data[3];

    const meal = new Gerichte();
    switch (this.type) {
      case 'warengruppe':

          this.title = decodeURIComponent(data[4].replace('*', '').replace('-', ' / '));
          meal.searchCategory(this.term).then(() => {
            this.results = meal.data;
          });
          break;
      case 'search':
          this.title = decodeURIComponent(this.term.replace('*', '').replace('-', ' / '));
          meal.search(this.title).then(() => {
            this.results = meal.data;
          });
        break;
    }
  }


  ngOnInit(): void {
    document.body.className = 'warengruppe';
  }

}
