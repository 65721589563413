import { environment } from '../..//environments/environment';
import * as CryptoJS from 'crypto-js';
import * as CryptoTS from 'crypto-ts';

export class HasherHelper {

  private salt: string;

  constructor() {
    this.salt = environment.hs;
  }

  public decrypt(hash: string): any {
    // console.log(hash);
    const keys =  this.salt;
    const key2 = CryptoJS.enc.Utf8.parse(keys);
    const data = CryptoJS.enc.Hex.parse(hash);
    const base64Data = data.toString(CryptoJS.enc.Base64);
    const bytes = CryptoJS.AES.decrypt(base64Data, key2, {

      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    if (bytes.toString()) {
      try {
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      } catch( Exception: any  ) {
        const regex = /(%)/gmi;
        const regexMeutral = /(\n)/gmi;
        const manySingleSpaces = /([\s]{2,})/gmi;
        const date = bytes.toString(CryptoJS.enc.Base64);
        let decoded = atob(date);
        decoded = decoded.replace(regexMeutral,' ').replace(manySingleSpaces,'').replace('"P"','\'P\'');
        try {
          return JSON.parse(decoded);
        } catch( Exception: any  ) {
          return decoded;
        }
      }
    }
  }

  private base64_encode(plain: string): any {
    const words = CryptoJS.enc.Utf8.parse(plain);
    const encoded = CryptoJS.enc.Base64.stringify(words);
    return encoded;
  }

  public setKey(key: string): void {
    this.salt = key;
  }

  public encryp(str: string): string {
    const keys =  this.salt;
    const keyword1 = CryptoJS.enc.Utf8.parse(keys);


    const encrypted = CryptoJS.AES.encrypt(str, keyword1, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    // console.log(encrypted);
    // console.log(this.decryptDepartmentId(encrypted));
    const encyptedBase64String = encrypted.ciphertext;
    const data =  encyptedBase64String.toString();
    return data;
  }

  public decryptDepartmentId(str: any): string {
    if (str === null) {
      return '';
    }
    return this.decrypt(JSON.parse(str).value);
  }

}
