import AppLocale from '../../templates/default/localization';
import PreperatorReplace from '../../templates/default/localization/preperator/replace';
import OwnerName from '../../templates/default/localization/preperator/owner_name';

interface IntlProps {
    messageId: string,
    locale: string,
    preperator?: string,
    param?: string,
    replace?: string | Element | number,
    htmlReplace?: boolean;
}

// tslint:disable-next-line:max-line-length
const IntlMessage = (props: IntlProps) => {

    const locale = (props.locale !== undefined && props.locale !== null ? props.locale.toLowerCase() :  'de');

    let languageFile;
    if (AppLocale[locale] !== undefined) {
        languageFile = AppLocale[locale]?.messages;

        /**
         *  Falls vorhanden wird der Language string entnommen.
         */

        let messageValue = languageFile[props.messageId];

        if (messageValue === undefined) {
            messageValue = props.messageId;
        }

        /**
         * Preperatoren werden durchlaufen falls einer angegeben wurde.
         *
         */
        switch (props.preperator) {
            case 'replace':
                const searchAndReplace = new PreperatorReplace(messageValue, props.param, props.replace);
                messageValue = searchAndReplace.Init();
                break;
            case 'owner':
                const preperatorOwnerName = new OwnerName(messageValue);
                messageValue = preperatorOwnerName.Init();
                break;
        }

        if (props.htmlReplace !== undefined && props.htmlReplace === true) {
            messageValue = replaceHTMLTag(messageValue);
        }


        return (messageValue);
    } else {
        return ('');
    }

};

export default IntlMessage;


// tslint:disable-next-line:max-line-length
export const IntlMessageValue = (locale: any, messageId: any, preperator: string = '', param: string = '', replace: string | number | Element = '', htmlReplace?: boolean) => {


    const localeValue = (locale !== undefined && locale !== null ? locale.toLowerCase() :  'de');

    let languageFile;

    if (AppLocale[localeValue] !== undefined) {
        languageFile = AppLocale[localeValue]?.messages;

        /**
         *  Falls vorhanden wird der Language string entnommen.
         */

        let messageValue = languageFile[messageId];

        if (messageValue === undefined) {
            messageValue = messageId;
        }

        /**
         * Preperatoren werden durchlaufen falls einer angegeben wurde.
         *
         */
        switch (preperator) {
            case 'replace':
                const searchAndReplace = new PreperatorReplace(messageValue, param, replace);
                messageValue = searchAndReplace.Init();
                break;
            case 'owner':
                const preperatorOwnerName = new OwnerName(messageValue);
                messageValue = preperatorOwnerName.Init();
                break;
        }

        if (htmlReplace !== undefined && htmlReplace === true) {
            messageValue = replaceHTMLTag(messageValue);
        }


        return (messageValue);
    } else {
        return ('');
    }
};

const replaceHTMLTag = (data: any) => {

    let htmlReplace = data;
  // tslint:disable-next-line:prefer-for-of
    for (let x = 0; x < mapSearchAndReplace().length; x++){
        htmlReplace =  htmlReplace.replaceAll(mapSearchAndReplace()[x].search, mapSearchAndReplace()[x].replace);
    }

    return htmlReplace.replaceAll(/&lt;/g, '<').replaceAll(/&gt;/g, '>').replaceAll(/&amp;/g, '&');

};


const mapSearchAndReplace = () => {

    const map = [
        {search: '[bold]', replace: '<strong>'},
        {search: '[/bold]', replace: '</strong>'}
    ];
    return map;

};
