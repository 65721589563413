
import {environment} from '../../environments/environment';
import intlMessage from './const/IntlMessage';

export class Core {
  public intlMessage: (text: string) => any | string;

  constructor() {
    this.intlMessage = Core.intlMessage;
  }



  static intlMessage(text: string) {
    const local = (localStorage.getItem('locale') !== undefined && localStorage.getItem('locale') !== null
      ? localStorage.getItem('locale') : environment.locale);
    const Lang = intlMessage({messageId: text, locale: (typeof local !== 'string' ? 'de' : local)});
    return Lang;
  }
}
