<div id="wrapper">

  <div class="second">

    <div id="ContainerProduct"><h1 id="teaserProduct">{{ title }}</h1></div>
    <div class="holder sec">

      <div class="row">
        <div id="Pin1" class="col mat-tab-label mat-tab-label-active" (click)="tabsetter('Pin1','tab1')" style="margin-left: 10px;">{{Tabs[0].label}}</div>
        <div id="Pin2" class="col mat-tab-label" (click)="tabsetter('Pin2','tab2')" >{{Tabs[1].label}}</div>
        <div id="Pin3" class="col mat-tab-label" (click)="tabsetter('Pin3','tab3')">{{Tabs[2].label}}</div>
        <div id="Pin4" class="col mat-tab-label" style="margin-right: 10px;" (click)="tabsetter('Pin4','tab4')">{{Tabs[3].label}}</div>
    <!--    <div id="Pin5" class="col mat-tab-label" style="margin-right: 10px;" (click)="tabsetter('Pin5','tab5')">{{Tabs[4].label}}</div>-->
      </div>

      <div id="tab1" [innerHtml]="Tabs[0].content" class="space_content mh65vh" ></div>
      <div id="tab2" [innerHtml]="Tabs[1].content" class="space_content mh65vh" style="display: none"></div>
      <div id="tab3" [innerHtml]="Tabs[2].content" class="space_content mh65vh" style="display: none"></div>
      <div id="tab4" [innerHtml]="Tabs[3].content" class="space_content mh65vh" style="display: none"></div>
     <!-- <div id="tab5" [innerHtml]="Tabs[4].content" class="space_content mh65vh" style="display: none"></div>-->

      <mat-tab-group animationDuration="0ms" style="display:none"  >
        <mat-tab label="{{Tabs[0].label}}">
          <div [innerHtml]="Tabs[0].content" class="space_content"></div>
        </mat-tab>
        <mat-tab label="{{Tabs[1].label}}" >
          <div [innerHtml]="Tabs[1].content" class="space_content"></div>
        </mat-tab>
        <mat-tab label="{{Tabs[2].label}}">
          <div [innerHtml]="Tabs[2].content" class="space_content"></div>
        </mat-tab>
        <mat-tab label="{{Tabs[3].label}}">
          <div [innerHtml]="Tabs[3].content" class="space_content"></div>
        </mat-tab>
        <!--<mat-tab label="{{Tabs[4].label}}">
          <div [innerHtml]="Tabs[4].content" class="space_content"></div>
        </mat-tab>-->
      </mat-tab-group>
    </div>
  </div>
  <br />
  <app-back></app-back>
</div>
