import { Injectable } from '@angular/core';
import {ApiClientService} from './Http/api-client.service';
import {UserInterface} from '../interfaces/user';
import { environment } from '../../environments/environment';
import {UserHashInterface} from '../interfaces/userHash';
import {HasherHelper} from '../Helper/hasher';
import {User} from '../entity/user';
import {GerichteInterface} from '../interfaces/gerichte';

@Injectable({
  providedIn: 'root'
})
export class ArticleService {
  public entpoint = 'dw/Request/kladde/GetArticlesBaseData?departmentid=' + environment.departmentid + '&date=1663056000';
  public apiClient: ApiClientService;
  public article: GerichteInterface[];

  constructor(apiClientService: ApiClientService, private hasher: HasherHelper) {
      this.apiClient = apiClientService;
      this.article = [];

  }

  // I load the list of friends.
  public async syncArticle(): Promise<GerichteInterface[] | undefined> {

    try {
      const userHash = await this.apiClient.get<UserHashInterface>({
        url: environment.root + this.entpoint,

      });
      const regex = /{([a-zA-Z0-9]*)}/gmi;

      this.hasher.setKey(environment.hs);

      const array = [...userHash.data.articles.matchAll(regex)];
      for(const cur of array) {
          const article = this.hasher.decrypt(cur[1]);
          article.clientname = environment.departmentid;
          this.apiClient.post<any>({
            url: environment.localroot + '/meals/'

          }, article);
      }

      /*
      console.log(this.users);
      for (const user of this.users) {
          this.hasher.setKey(environment.hash);
          user.lastname = this.hasher.encryp(user.lastname);
          user.firstname = this.hasher.encryp(user.firstname);
          user.departmentid = this.hasher.encryp(user.departmentid);
          this.apiClient.post<any>({
            url: environment.localroot + '/users/'

          }, user);
      }
;

      return this.users;
*/
      return undefined;
    } catch ( error ) {

      console.error( error );
      return undefined;

    }

  }
}
