import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomeComponent} from './components/home/home.component';
import {SwitcherComponent} from './components/switcher/switcher.component';
import {LoginComponent} from './components/login/login.component';
import {LoginActivate} from './guards/LoginActivate';
import {GoodsGroupComponent} from './components/goods_group/goods_group.component';
import {SyncComponent} from './components/sync/sync.component';
import {SearchResultComponent} from './components/search-result/search-result.component';
import {ProductDetailComponent} from './components/produktDetail/productDetail.component';
import {SearchWordComponent} from './components/search-word/search-word.component';
import {ComponentsComponent} from './components/components/components.component';
import {ComponentsappetizersComponent} from './components/componentsappetizers/componentsappetizers.component';
import {ComponentsKwComponent} from './components/components-kw/components-kw.component';
import {ProduktDetailSPComponent} from './components/produkt-detail-sp/produkt-detail-sp.component';

const routes: Routes = [
  { path: '', component: HomeComponent , canActivate: [LoginActivate] },
  { path: 'step2', component: SwitcherComponent, canActivate: [LoginActivate] },
  { path: 'goods-group', component: GoodsGroupComponent, canActivate: [LoginActivate] },
  { path: 'search-word', component: SearchWordComponent, canActivate: [LoginActivate] },
  { path: 'components', component: ComponentsComponent, canActivate: [LoginActivate] },
  { path: 'components/group/:name/:day', component: ComponentsKwComponent, canActivate: [LoginActivate] },
  { path: 'components/appetizers/:name', component: ComponentsappetizersComponent, canActivate: [LoginActivate] },
  { path: 'components/appetizers/:name/:kw/:day', component: ComponentsappetizersComponent, canActivate: [LoginActivate] },
  { path: 'step3a', component: GoodsGroupComponent, canActivate: [LoginActivate] },
  { path: 'productDetail/:name', component: ProductDetailComponent, canActivate: [LoginActivate] },
  { path: 'productDetail/:kw/:day/:name', component: ProduktDetailSPComponent, canActivate: [LoginActivate] },
  { path: 'result/search/:term', component: SearchResultComponent, canActivate: [LoginActivate] },
  { path: 'result/warengruppe/:wg', component: SearchResultComponent, canActivate: [LoginActivate] },
  { path: 'result/warengruppe/:wg/:name', component: SearchResultComponent, canActivate: [LoginActivate] },
  { path: 'login', component: LoginComponent },
  { path: 'sync', component: SyncComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
