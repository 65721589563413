import { LOCALE_ID, NgModule, Component, OnInit } from '@angular/core';
import {Core} from '../../core';
import {Categroy} from '../../entity/category';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Observer} from 'rxjs';
import {Gerichte} from '../../entity/gerichte';
import {Allergenes} from '../../Model/allergenes';
import {Nutrient} from '../../Model/nutrient';
import {Extras} from '../../Model/extras';
import {Infos} from '../../Model/infos';
import {formatNumber} from '@angular/common';

export interface ExampleTab {
  label: string;
  content: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './productDetail.component.html',
  styleUrls: ['./productDetail.component.css'],
})
export class ProductDetailComponent extends Core implements OnInit  {
  term = '';
  Tabs: any;
  id = "";
  title: any;
  private tabswitcher: any;



  constructor(private actRoute: ActivatedRoute) {
    super();
    this.term = this.actRoute.snapshot.params.name;
    // console.log(this.term);
    this.id = this.term.split('__')[1];

    // console.log(this.id);
    const meals = new Gerichte();
    meals.findById(this.id).then(() => {
      const regex = /(,)/gmi;
      this.title = meals.data[0].nameb4b;
      const meal = meals.data[0];
      this.Tabs = [
            { label: 'Nährwerte', content: this.nutrients(meal) },
            { label: 'Allergene', content: this.allergenes(meal) },
            { label: 'Zusatzstoffe', content: this.extras(meal)},
            { label: 'Informationen', content: this.infos(meal) },
            { label: 'Zutaten', content: meal.zutaten.replace(regex, ',<br>') },
          ];
      });
    this.tabsetter;
    }



  ngOnInit(): void {

    document.body.className = 'details';
  }

  tabsetter(Tabid: any, Tabtarget: any): any {


    let activePin;
    let deactivePin;
    let activeTab;
    let deactiveTab;
    let x = 0;
    const tabs = ['tab1', 'tab2', 'tab3', 'tab4', 'tab5'];
    const pins = ['Pin1', 'Pin2', 'Pin3', 'Pin4', 'Pin5'];

    for (x = 0; x  < tabs.length; x++){
      if (Tabid === pins[x]) {
        activePin = document.getElementById(Tabid) as HTMLDivElement;
        activeTab = document.getElementById(Tabtarget) as HTMLDivElement;

        activePin.classList.add('mat-tab-label-active');
        activeTab.style.display = 'block';

      } else {
        deactivePin = document.getElementById(pins[x])  as HTMLDivElement;
        deactivePin.classList.remove('mat-tab-label-active');
        deactiveTab = document.getElementById(tabs[x])  as HTMLDivElement;
        deactiveTab.style.display = 'none';
      }
    }
    return;

  }

  infos(response: any): any {
    let holder = '';
    const infos = new Infos().get();
    for (const item of infos) {
      if (response[item.ident] === 1 || response[item.ident] === 'true') {
        holder += '<p>' + item.name + '</p>';
      }
    }
    if (holder === ''){
      holder = 'Keine Angabe';
    }
    return holder;
  }

  extras(response: any): any {
    let holder = '';
    const extras = new Extras().get();
    let counter = 0;
    for (const item of extras) {
      if (response[item.ident] === 1 || response[item.ident] === 'true') {
        counter++;
        holder += '<p>' + item.name + '</p>';
      }
    }
    if (counter === 0) {
      holder = 'keine Zusatzstoffe';
    }
    return holder;
  }

  nutrients(response: any): any {
    let holder = '<div class="row mb-4"><div class="col-12">Durchschnittliche Nährwerte pro 100 g</div></div>';
    const nutirent = new Nutrient().get();
    for (const item of nutirent) {
      if (response[item.ident] !== null || response[item.ident] === 'true') {
        if ( item.ident === 'fssss' || item.ident === 'zucke' ) {
          holder += '<div class="row mb-4 mt_n1"><div class="col-6 ps-5">davon ' + item.name + '</div><div class="col-3 text-right">  '
            + this.fixDimension(item.ident, response[item.ident]) + '</div></div>'; // parseFloat().toFixed(2).replace('.','
        } else {
          holder += '<div class="row mb-4"><div class="col-6" >' + item.name + '</div><div class="col-3 text-right" > '
            + this.fixDimension(item.ident, response[item.ident]) + '</div></div>'; // parseFloat().toFixed(2).replace('.',',')
        }
      }
    }
    return holder;
  }

  fixDimension(index: string , value: any): any  {
    if (index === 'kcale' || index === 'kjene') {
        if (index === 'kcale'){
          return parseInt(value) + ' kcal';
        } else {
          return parseInt(value) + ' kj';
        }
    } else {
      if (index === 'kochs' ) {
        const cur =  parseFloat(value) / 1000;
        return cur.toFixed(2).replace('.', ',') + ' g';
      } else {
        const cur =  parseFloat(value) / 1000;
        return cur.toFixed(1).replace('.', ',') + ' g';
      }
    }
  }


 allergenes(response: any): any {
    const allergene = new Allergenes().get();
    let holder = '';
    let counter = 0;
    for (const item of allergene) {
      if (response[item.ident] === 1 || response[item.ident] === 'true') {
        counter++;
        let intent = '';
        let separator = '';
        if (item.tab === true){
          intent = 'class="ms-3" style="margin-top: -1em;" ';
          separator = ' &bull; ';
        }
        holder += '<p ' + intent + ' >' + separator + item.name + '</p>';
      }
    }
    if (counter === 0) {
      holder = 'keine Allergene';
    }
    return holder;
}



}
