export const environment = {
  production: false,
  locale: 'de',
  us: 'uvs!]Wb=',
  username: '',
  password: '',
  hs: 'hWmZq3t6w9z$C&F)J@NcRfUjXn2r5u7x',
  root: 'https://kladdetest.best4bps.io:8443/',
  localroot: 'https://kladdetest.best4bps.io',
  // localroot: 'http://angular-trapez-dev.de:4201',
  cost_center: 'PWKS_Paul-Wilhelm_von_Keppler_Stiftung',
  departmentid: '20ca2df9-e0d1-489d-9494-1a7799e69929',
  hash: 'GDnDBnJ4HKI8Md2fuB1JsH0xvcnXUgsl'
};
