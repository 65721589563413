

export class MenuComponents {

  public static getComponent(Menuelinie: any, id: boolean = false): any {
      const data = [];
      const Komponenten = Menuelinie['Komponenten'][0]['Komponente'];
      // console.log(Komponenten);

      for (const Komponente in Komponenten) {
        if( id ) {
          data.push({id:Komponenten[Komponente]['Produkte'][0]['Produkt'][0].Id[0],comp:Komponenten[Komponente]['Produkte'][0]['Produkt'][0].Bezeichnung[0]});
        } else {
          data.push(Komponenten[Komponente]['Produkte'][0]['Produkt'][0].Bezeichnung[0]);
        }
      }
      return data;

  }

  public static getComponentByName(Menuelinie: any, name:string): any {
    const data = [];
    const Komponenten = Menuelinie['Komponenten'][0]['Komponente'];
    // console.log(Komponenten);
    for (const Komponente in Komponenten) {
      const comp = Komponenten[Komponente]['Produkte'][0]['Produkt'][0];
      if (name === comp.Id[0]) {
        return comp;
      }

    }
  }

}
