<div id="wrapper">

  <form [formGroup]="loginForm" (submit)="login()" class="logincontent">
    <div>

      <h1>Login</h1>
      <label for="username">Username</label>
      <input formControlName="account" id="username" required type="text">
      <label for="password">Password</label>
      <input formControlName="password" id="password" type="password">
    </div>
    <div class="actions">
      <button>Einloggen</button>
      <a href="/forgot">Passwort vergessen!</a>
    </div>

    <div id="loginResponse" class="alert alert-danger d-none" role="alert"></div>
  </form>
</div>
<!--<span>Don't have an account?
        <a routerLink="/signUp">signUp</a></span>-->
