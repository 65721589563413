import {Component, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'app-back',
  templateUrl: './back.component.html',
  styleUrls: ['./back.component.css']
})
export class BackComponent implements OnInit, OnChanges {
  public url: any;
  public check: boolean | undefined;

  constructor(private location: Location) { }
  ngOnInit(): void {
    this.url = window.location.pathname;
    this.check = this.url !== '/step2';
  }
  back(): void {
      this.location.back();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.url = window.location.pathname;
    this.check = this.url !== '/step2';
  }

}
