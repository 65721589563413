
export class NutrientXML {

  get(): any {
    return  [
      {name: 'Energie Kcal', ident: 'Energie', messure: 'kcal'},
      {name: 'Energie KJ ', ident: 'EnergieKj', messure: 'kj'},
      {name: 'Eiweiß ', ident: 'Eiweiss', messure: 'g'},
      {name: 'Fett ', ident: 'Fett', messure: 'g'},
      {name: 'gesättigte Fettsäuren ', ident: 'GesaettigteFettsaeuren', messure: 'g'},
      {name: 'Kohlenhydrate ', ident: 'KohlenhydrateResorbierbare', messure: 'g'},

      {name: 'Zucker ', ident: 'Zucker', messure: 'g'},
      {name: 'Salz ', ident: 'Kochsalz', messure: 'g'},


    ];
  }

}



