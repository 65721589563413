import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Core} from '../../core';
import {environment} from '../../../environments/environment';
import {ApiClientService} from '../../services/Http/api-client.service';
import {HasherHelper} from '../../Helper/hasher';

@Component({
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent extends Core implements OnInit {
  public loginForm!: FormGroup;
  constructor(private formbuilder: FormBuilder, private  apiClient: ApiClientService, private router: Router) {
    super();
  }
  ngOnInit(): void {
    document.body.className = 'login';

    this.loginForm = this.formbuilder.group({
      account: [''],
      password: ['', Validators.required]
    });
    if (localStorage.getItem('isLoggedIn') !== undefined && localStorage.getItem('isLoggedIn') === 'true') {
        this.router.navigate(['/']);
    }

  }

  login(): void {
    this.apiClient.post<any>({url: environment.localroot + '/users/auth'},
      {account: this.loginForm.value.account, password: this.loginForm.value.password})
      .then(res => {
        if (res.login) {
          const date = new Date();
          date.setMinutes(date.getMinutes() + 30);
          const item = {
            id: res.uid,
            departmentid: res.departmentid,
            user: res.user,
            value: 'true',
            expiry: date.getTime(),
          };
          const departmentid = {
            id: res.uid,
            value: res.departmentId,
            expiry: date.getTime(),
          };
          const user = {
            id: res.uid,
            value: res.user,
            expiry: date.getTime(),
          };
          localStorage.setItem('isLoggedIn', JSON.stringify(item));
          localStorage.setItem('departmentid', JSON.stringify(departmentid));
          localStorage.setItem('user', JSON.stringify(user));
          this.router.navigate(['/']);
        } else {
          const alert = document.getElementById('loginResponse');
          if (alert !== null) {
            alert.innerHTML = res;
            alert.classList.remove('d-none');
          };

          localStorage.setItem('isLoggedIn', res);
        }
        // console.log(res);
      });
  }
}
