import { Component, OnInit } from '@angular/core';

import {Core} from '../../core';
import {Location} from '@angular/common';

@Component({
  selector: 'app-switcher',
  templateUrl: './switcher.component.html',
  styleUrls: ['./switcher.component.css']
})
export class SwitcherComponent extends Core implements OnInit  {

  constructor(private location: Location) {
    super();
  }


  ngOnInit(): void {
    document.body.className = 'switcher';
  }
  back(): void {
    this.location.back();
  }

}
