<div id="wrapper">

  <div class="parent">
    <div class="row ">
      <div class="col-3"></div>
      <div class=" col-9">

        <div class="div1"><h1>Suche nach Komponenten</h1></div>
        <div class="div2">
          <div class="container">
            <h2 class="text-center">{{ title }}</h2>
            <div class="row" *ngFor="let component of item;">
              <p><a [routerLink]="['/productDetail/' + kw + '/' + day + '/'+ component.id + '_'+component.comp]">{{component.comp}}</a></p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>



  <app-back></app-back>

</div>
