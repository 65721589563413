import { Component, OnInit } from '@angular/core';
import {Core} from '../../core';
import {Categroy} from '../../entity/category';
import {Router} from '@angular/router';
import {Menu} from '../../entity/menu';
import {MenuComponents} from '../../Helper/MenuComponents';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-componentsappetizers',
  templateUrl: './componentsappetizers.component.html',
  styleUrls: ['./componentsappetizers.component.css']
})
export class ComponentsappetizersComponent extends Core implements OnInit {

  title: any;

  item: any;

  kw: any;

  days: any = [];

  day: any;
  compID: any;

  constructor(private router: Router) {
    super();

    this.title = window.location.pathname.split('/')[window.location.pathname.split('/').length - 3];
    this.kw = window.location.pathname.split('/')[window.location.pathname.split('/').length - 2];
    this.day  = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
    const weekday = [{day: 'montag', value: 0} , {day: 'dienstag', value: 1} , {day: 'mittwoch', value: 2} , {day: 'donnerstag', value: 3},
      {day: 'freitag', value: 4} , {day: 'samstag', value: 5} , {day: 'sonntag', value: 6}]
    const menuHelper = new Menu();

    let marker: number;

    for (let x = 0; x < weekday.length;x++){
      if (weekday[x].day === this.day) {
        marker = weekday[x].value;
      }
    }

    menuHelper.getWeek(this.kw).then(() => {
      for (const lieferung of menuHelper.data[0]['Auslieferung']) {
        const node = lieferung['Speiseplaene'][0]['Speiseplan'][0]['Menuelinien'][0]['Menuelinie'];
        for (const item of node) {
          if (item['Bezeichnung'][0] === 'Salatbuffet' || (UserService.getUser() == "STG" && item['Bezeichnung'][0] === 'Vorspeise 1')) {
            // Zum Holen der Compnenten von einer Menulinie
            this.days.push(item);
          }
        }

      }
      // Alle Saltebuffet sind nun in diesen Array 0 = Montag und so weiter
      console.log(this.days);
      console.log(marker);
      this.item = MenuComponents.getComponent(this.days[marker], true);
    });

  }


  ngOnInit(): void {
    document.body.className = 'componentsappetizers';
  }


}
