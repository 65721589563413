
<div id="wrapper">

  <div class="parent">
    <div class="row ">
      <div class="col-3"></div>
      <div class=" col-9">

        <div class="div1"><h1>Suche nach Wortbestandteilen</h1></div>
        <div class="div2">
          <div class="container grayout overflow-hidden spacingWord">
            <div class="row">
              <form (submit)="sendSearch($event)" class="text-center" >
                <input type="text" class="textg" name="term" id="term" placeholder="Geben Sie den Suchbegriff ein" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <app-back></app-back>

</div>
