import {ErrorHandler} from '@angular/core';
import {ApiClientService} from '../services/Http/api-client.service';
import {environment} from '../../environments/environment.dev';
import {UserService} from '../services/user.service';



export class Menu {
  protected apiClient: ApiClientService;
  public data: any;

  constructor( ) {
    const errorHandler = new ErrorHandler();
    this.apiClient = new ApiClientService(errorHandler);
  }

  async getWeek(week: any): Promise<void> {
      const data = await this.apiClient.get<any>({
                               url: environment.localroot + '/menu/plan/' + week + '/' + UserService.getUser() + '/'

      });
      this.data = data;
  }

  async getAvailWeek(): Promise<void> {
    const data = await this.apiClient.get<any>({
      url: environment.localroot + '/menu/plan/check/SKB/'

    });
    this.data = data;
  }

}
