import { Component, OnInit } from '@angular/core';
import {Gerichte} from '../../entity/gerichte';
import {Core} from '../../core';
import {Categroy} from '../../entity/category';
import {Router} from '@angular/router';
import {Menu} from '../../entity/menu';

@Component({
  selector: 'app-components',
  templateUrl: './components.component.html',
  styleUrls: ['./components.component.css']
})
export class ComponentsComponent extends Core implements OnInit {
  public category: Categroy;
  public kw: any;
  selectedValue: any;
  constructor(private router: Router) {
    super();
    this.category = new Categroy();
    this.category.getAllActiveCategory().then(() => {
      for (const data of this.category.data) {
        data.wgkey = data.WarengruppenschlüsselB4B;
      }
    });
    const menuHelper = new Menu();
    menuHelper.getAvailWeek().then(() => {
      this.kw = menuHelper.data;
    });
  }


  rewrite(value: any, mode: any): void{
    if (mode === false) {
      this.router.navigate(['/components/group/' + value + '/montag']);
    } else if (mode === true ){
      const date = new Date();
      const currentThursday = new Date(date.getTime() + (3 - ((date.getDay() + 6) % 7)) * 86400000);
      const yearOfThursday = currentThursday.getFullYear();
      const firstThursday = new Date(new Date(yearOfThursday, 0, 4).getTime() + (3 - ((new Date(yearOfThursday, 0, 4).getDay() + 6) % 7)) * 86400000);
      const weekNumber = Math.floor(1 + 0.5 + (currentThursday.getTime() - firstThursday.getTime()) / 86400000 / 7);
      this.router.navigate(['/components/appetizers/' + value + '/' + weekNumber + '/montag']);
    }

  }

  ngOnInit(): void {
    document.body.className = 'components';
  }


}
